import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import Layout from "../components/layout"

const SecondPage = () => (
  <Layout>
    <SEO title="Prowess" />

    <ContentContainer>
      <PaddedContainer>
        <HeroMainText>Thank you for your interest 🎉!</HeroMainText>
        <HeroSubText>
          We'll reach out via email as soon as possible to help you get started.
        </HeroSubText>
        <div
          dangerouslySetInnerHTML={{
            __html: `<a href="https://www.producthunt.com/posts/prowess?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-prowess" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=206588&theme=light" alt="Prowess - The simplest way to collect and leverage product feedback | Product Hunt Embed" style="width: 250px; height: 54px;" width="250px" height="54px" /></a>`,
          }}
        ></div>
      </PaddedContainer>
    </ContentContainer>
  </Layout>
)

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 72px;
`

const PaddedContainer = styled.div`
  display: flex;
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`

const HeroMainText = styled.h1`
  color: black;
  padding-top: 180px;
  font-size: 54px;
  font-weight: 800;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    padding-top: 72px;
    font-size: 40px;
    width: 100%;
  }
`

const HeroSubText = styled.h1`
  font-weight: 400;
  line-height: 28px;
  padding-top: 12px;
  font-size: 20px;
  color: #7f8297;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    font-size: 16px;
    width: 100%;
  }
`

const HeroSubSubText = styled.h1`
  padding-top: 12px;
  text-align: center;
  font-size: 14px;
  color: #7f8297;
  font-weight: 400;
  @media only screen and (max-width: 1000px) {
    font-size: 16px;
    width: 100%;
    font-size: 12px;
  }
`

export default SecondPage
